@import "~@/styles/variables/variables.scss";

























































.complete {
	margin: 50px auto;
	text-align: center;
	.icon {
		width: 72px;
		height: 72px;
	}
	.title {
		margin-top: 24px;
		font-size: 24px;
		font-weight: 500;
		color: #333;
	}
	.desc {
		margin-top: 20px;
		color: #999;
		.big {
			margin-left: 10px;
			font-size: 20px;
			font-weight: 500;
			&.success {
				color: #16a65d;
			}
			&.fail {
				color: #ffac05;
			}
		}
	}

	.box-btn {
		margin-top: 35px;
		.el-button {
			min-width: 100px;
			+ .el-button {
				margin-left: 13px;
			}
		}
	}
}
