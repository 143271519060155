@import "~@/styles/variables/variables.scss";















































































































































































































.check {
	.box-tip {
		margin: 0 auto;
		width: 40%;
		min-width: 400px;
		max-width: 640px;
		height: 64px;
		background: #e4f5ec;
		border-radius: 4px;
		display: flex;
		align-items: center;
		+ .box-tip {
			margin-top: 10px;
		}
		&.warning {
			background: #fdf5e7;
			.amount {
				color: #ffac05;
			}
		}
		.icon {
			width: 64px;
			height: 64px;
		}
		.text {
			margin-right: 8px;
			width: 165px;
			color: #333;
		}
		.amount {
			font-size: 24px;
			font-weight: 500;
			color: #16a65d;
		}
	}

	.box-btn {
		margin: 46px auto 24px;
		text-align: center;
		.el-button {
			min-width: 100px;
			+ .el-button {
				margin-left: 13px;
			}
		}
	}

	.box-table {
		margin-top: 50px;
		border-top: 1px dashed #d9d9d9;
		height: calc(100vh - 620px);
		min-height: 200px;
		.title {
			line-height: 55px;
			font-size: 16px;
			font-weight: 500;
			color: #333;
		}
	}
}
