@import "~@/styles/variables/variables.scss";



























































.container {
	padding: 24px;
}
.step {
	height: 112px;
	@include flex-center;
	&-item {
		@include flex-center;
		&.active {
			.num {
				border: 1px solid $base-color-theme;
				color: #fff;
				background: $base-color-theme;
			}
			.title {
				color: $base-color-theme;
				font-weight: 500;
				color: #333;
			}
		}
		&.pass {
			.num {
				border: 1px solid $base-color-theme;
				color: $base-color-theme;
			}
			.title {
				color: $base-color-theme;
				color: $base-color-theme;
			}
			.line {
				background-color: $base-color-theme;
			}
		}

		.num {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			border: 1px solid #999;
			color: #999;
			@include flex-center;
		}
		.title {
			margin-left: 8px;
			font-size: 16px;
			color: #999;
		}
		.line {
			margin: 0 16px;
			width: 10vw;
			min-width: 50px;
			height: 1px;
			background-color: #d9d9d9;
		}
	}
}
.icon {
	width: 100px;
	height: 100px;
}
