@import "~@/styles/variables/variables.scss";

































































































































































































.upload {
	width: 70%;
	margin: 0 auto;

	.box {
		border-radius: 4px;
		border: 1px dashed #d9d9d9;
		display: flex;
		align-items: center;
		+ .box {
			margin-top: 20px;
		}
		.icon {
			width: 136px;
			height: 136px;
		}
		.content {
			padding: 31px 16px 25px;
			.title {
				font-size: 16px;
				font-weight: 500;
				color: #333;
			}
			.desc {
				margin-top: 16px;
				color: #c9c9c9;
			}
			.btn {
				margin-top: 19px;
				cursor: pointer;
				color: $base-color-theme;
			}
			.file-name {
				margin-top: 19px;
				color: #666;
				display: flex;
				align-items: center;
				.iconfont {
					margin-left: 25px;
					cursor: pointer;
					color: #000;
				}
			}
		}
	}

	.box-btn {
		margin: 64px auto 24px;
		text-align: center;
	}
}
